<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>
    <section class="body">
      <h2 class="section-title">{{ name }}</h2>
      <form-import @submit="submited" />
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }} Table</h4>
              <div class="card-header-form">
                <div class="input-group">
                  <import />
                  <router-link
                    class="btn btn-primary mr-1"
                    :to="{ name: 'PayrollCreate' }"
                    >Create
                  </router-link>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                    v-model="search"
                    label="Search"
                  />
                  <div class="input-group-btn">
                    <button class="btn btn-primary">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="card card-body">
                <div class="row">
                  <div class="col-6">
                    <label>Name</label>
                    <model-list-select
                      :list="users"
                      v-model="filter.admin_id"
                      option-value="id"
                      option-text="username"
                      placeholder="Select User"
                    >
                    </model-list-select>
                  </div>
                  <div class="col-6">
                    <label>Select Venue</label>
                    <select class="form-control" v-model="filter.venue_id">
                      <option value="">Select Venue</option>
                      <option :value="0">All Venue</option>
                      <option
                        :value="venue.id"
                        v-for="(venue, index) in venues"
                        :key="index"
                        >{{ venue.title }}</option
                      >
                    </select>
                  </div>
                  <div class="col-6">
                    <label>Select Month</label>
                    <select class="form-control" v-model="filter.month">
                      <option value="">Select Month</option>
                      <option
                        :value="index"
                        v-for="(m, index) in month"
                        :key="index"
                        >{{ m }}</option
                      >
                    </select>
                  </div>
                  <div class="col-6">
                    <label>Year</label>
                    <input
                      type="number"
                      class="form-control"
                      min="1900"
                      max="2099"
                      step="1"
                      v-model="filter.year"
                    />
                  </div>
                  <div class="col-md-3">
                    <button class="btn btn-primary w-100" @click="getData()">
                      Filter
                    </button>
                  </div>
                  <div class="col-md-3">
                    <button class="btn btn-primary w-100" @click="getSample()">
                      Download Sample Data
                    </button>
                  </div>
                  <div class="col-md-3">
                    <button
                      class="btn btn-primary w-100"
                      @click="getExportHistory()"
                    >
                      Export History
                    </button>
                  </div>
                  <div class="col-md-3">
                    <button
                      class="btn btn-primary w-100"
                      @click="getExportSlip()"
                    >
                      Export Slip
                    </button>
                  </div>
                </div>
              </div>
              <v-progress-circular
                v-if="loading"
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <v-data-table
                :headers="headers"
                :items="payrolls.data"
                item-key="id"
                :search="search"
                hide-default-footer
                :page.sync="page"
                :items-per-page="itemsPerPage"
                class="elevation-1"
                @page-count="pageCount = $event"
                v-else
              >
                <template v-slot:item="row">
                  <tr>
                    <td>{{ row.item.month }}/{{ row.item.year }}</td>
                    <td>{{ row.item.venue_name }}</td>
                    <td>{{ row.item.admin_name }}</td>
                    <td>{{ row.item.role_name }}</td>
                    <td>{{ row.item.point_service }}</td>
                    <td>Rp. {{ formatPrice(row.item.umk) }}</td>
                    <td>Rp. {{ formatPrice(row.item.thr) }}</td>
                    <td>Rp. {{ formatPrice(row.item.payroll) }}</td>
                    <td>{{ row.item.presence_count }}</td>
                    <td>Rp. {{ formatPrice(row.item.service_charge) }}</td>
                    <td>Rp. {{ formatPrice(row.item.bonus_other) }}</td>
                    <td>{{ row.item.overtime_count }}</td>
                    <td>Rp. {{ formatPrice(row.item.overtime_bonus) }}</td>
                    <td>{{ row.item.late_presence_count }}</td>
                    <td>{{ row.item.alpha_presence_count }}</td>
                    <td class="text-danger">
                      Rp. {{ formatPrice(row.item.late_presence_cut) }}
                    </td>
                    <td class="text-danger">
                      Rp. {{ formatPrice(row.item.alpha_presence_cut) }}
                    </td>
                    <td class="text-danger">
                      Rp. {{ formatPrice(row.item.debt_cut) }}
                    </td>
                    <td class="text-danger">
                      Rp. {{ formatPrice(row.item.food_and_beverage_cut) }}
                    </td>
                    <td>Rp. {{ formatPrice(row.item.additional_payroll) }}</td>
                    <td class="text-danger">
                      Rp. {{ formatPrice(row.item.bpjs) }}
                    </td>
                    <td class="font-weight-bold">
                      Rp. {{ formatPrice(row.item.total_payroll) }}
                    </td>
                    <td>{{ row.item.payroll_type }}</td>
                    <td>{{ row.item.account_bank_name }}</td>
                    <td>{{ row.item.account_name }}</td>
                    <td>{{ row.item.account_number }}</td>

                    <td>
                      <router-link
                        :to="{
                          name: 'PayrollEdit',
                          params: { id: row.item.id },
                        }"
                        class="badge badge-primary mr-1"
                        >Show</router-link
                      >
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <div class="card-footer text-right">
              <nav class="d-inline-block">
                <ul class="pagination mb-0">
                  <v-pagination
                    v-model="page"
                    :length="payrolls.last_page"
                    total-visible="7"
                    @input="getData"
                  ></v-pagination>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import { apiDownload, apiGet, apiPost } from "../../services/api";
import DownloadSample from "./DownloadSample.vue";
import Import from "./Import.vue";
import FormImport from "./FormImport.vue";

export default {
  name: "Payroll",
  components: {
    Import,
    FormImport,
  },
  data() {
    return {
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      name: "Payroll",
      payrolls: [],
      loading: false,
      filter: {
        name: "",
        venue_id: "",
        admin_id: "",
        month: "",
        year: new Date().getFullYear(),
      },
      users: [],
      venues: [],
      month: {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
        13: "THR",
      },
    };
  },
  mounted() {
    this.getData();
  },
  // created(){
  //   this.getData();
  // },
  methods: {
    deletePayroll(id) {
      swal({
        title: "Are you sure?",
        text:
          "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .delete(this.$store.state.api + "payment_type/" + id, {
              headers: { Authorization: this.$store.state.token },
            })
            .then(
              () =>
                (this.payrolls = this.payrolls.filter((v) => {
                  return v.id != id;
                }))
            )
            .catch((err) => {
              console.log(err);
              swal("Error!", err.message, "error");
            });
          swal("Poof! Your Payroll file has been deleted!", {
            icon: "success",
          });
        } else {
          swal("Your Payroll file is safe!");
        }
      });
    },
    submited() {
      this.$noty.success("Data successfully imported");
      this.getData();
    },
    getData() {
      this.loading = true;
      apiGet("payroll", this.filter).then((result) => {
        this.payrolls = result.data.data;
        apiGet("venue").then((result) => {
          this.venues = result.data.data;
          apiGet("admin?payroll_info=1").then((result) => {
            this.users = result.data.data;
            this.loading = false;
          });
        });
      });
    },
    getSample() {
      axios({
        url: this.$store.state.api + "payroll/download-sample",
        method: "GET",
        responseType: "blob",
        params: {
          venue_id: this.filter.venue_id,
        },
      }).then((response) => {
        if (!window.navigator.msSaveOrOpenBlob) {
          // BLOB NAVIGATOR
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "sample_payroll.xlsx");
          document.body.appendChild(link);
          link.click();
          console.log(response);
        } else {
          // BLOB FOR EXPLORER 11
          const url = window.navigator.msSaveOrOpenBlob(
            new Blob([response.data]),
            "sample_payroll.xlsx"
          );
          console.log(response);
        }
      });
    },
    getExportHistory() {
      axios({
        url: this.$store.state.api + "payroll/export",
        method: "GET",
        responseType: "blob",
        params: {
          venue_id: this.filter.venue_id,
          admin_id: this.filter.admin_id,
          month: this.filter.month,
          year: this.filter.year,
        },
      }).then((response) => {
        if (!window.navigator.msSaveOrOpenBlob) {
          // BLOB NAVIGATOR
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "export_payroll.xlsx");
          document.body.appendChild(link);
          link.click();
          console.log(response);
        } else {
          // BLOB FOR EXPLORER 11
          const url = window.navigator.msSaveOrOpenBlob(
            new Blob([response.data]),
            "export_payroll.xlsx"
          );
          console.log(response);
        }
      });
    },
    getExportSlip() {
      axios({
        url: this.$store.state.api + "payroll/export/slip",
        method: "GET",
        responseType: "blob",
        params: {
          venue_id: this.filter.venue_id,
          admin_id: this.filter.admin_id,
          month: this.filter.month,
          year: this.filter.year,
        },
      }).then((response) => {
        if (!window.navigator.msSaveOrOpenBlob) {
          // BLOB NAVIGATOR
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "slip.pdf");
          document.body.appendChild(link);
          link.click();
          console.log(response);
        } else {
          // BLOB FOR EXPLORER 11
          const url = window.navigator.msSaveOrOpenBlob(
            new Blob([response.data]),
            "slip.pdf"
          );
          console.log(response);
        }
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Period",
          value: "month",
        },
        {
          text: "Venue",
          value: "venue_name",
        },
        {
          text: "Name",
          value: "admin_name",
        },
        {
          text: "Role",
          value: "role_name",
        },
        {
          text: "Point",
          value: "point_service",
        },
        {
          text: "UMK",
          value: "umk",
        },
        {
          text: "THR",
          value: "thr",
        },
        {
          text: "Payroll",
          value: "payroll",
        },
        {
          text: "Presence",
          value: "presence_count",
        },
        {
          text: "Service Charge",
          value: "presence_count",
        },
        {
          text: "Bonus",
          value: "bonus_other",
        },
        {
          text: "Overtime",
          value: "overtime_count",
        },
        {
          text: "Overtime Total",
          value: "overtime_bonus",
        },
        {
          text: "Late Hour",
          value: "late_presence_count",
        },
        {
          text: "Alpha Day",
          value: "alpha_presence_count",
        },
        {
          text: "Late Cut",
          value: "late_presence_cut",
        },
        {
          text: "Alpha Cut",
          value: "alpha_presence_cut",
        },
        {
          text: "F&B Cut",
          value: "food_and_beverage_cut",
        },
        {
          text: "Debt Cut",
          value: "debt_cut",
        },
        {
          text: "Additional Payroll",
          value: "additional_payroll",
        },
        {
          text: "BPJS",
          value: "bpjs",
        },
        {
          text: "Total Payroll",
          value: "total_payroll",
        },
        {
          text: "Payroll Type",
          value: "payroll_type",
        },
        {
          text: "Bank Account",
          value: "account_bank_name",
        },
        {
          text: "Account Name",
          value: "account_name",
        },
        {
          text: "Account Number",
          value: "account_number",
        },
        { text: "Action" },
      ];
    },
  },
};
</script>
